import React, {useState, useEffect} from 'react';
import Link from '../components/Link';
import Seo from '../components/Seo';
import Helmet from 'react-helmet';
import BlogBox from '../components/BlogBox';


export default ({pageContext, location}) => {
    const {seo = {}, header, results = []} = pageContext;
    const metaTitle = seo.metaTitle || 'Clean Medicine Blog | Genexa';
    const openGraphTitle = seo.openGraphTitle || 'Clean Medicine Blog | Genexa';
    const twitterTitle = seo.twitterTitle || 'Clean Medicine Blog | Genexa';

    return (
        <React.Fragment>
            <Helmet title="Search - Genexa"/>
            <Seo
                metaTitle={metaTitle}
                metaDescription={seo.metaDescription}
                metaKeywords={seo.metaKeywords}
                openGraphTitle={openGraphTitle}
                openGraphDescription={seo.openGraphDescription}
                openGraphImage={seo.openGraphImage}
                twitterTitle={twitterTitle}
                twitterDescription={seo.twitterDescription}
                twitterImage={seo.twitterImage}
                pathname={location.pathname}
            />

            <div className="blog-container">
                <div className="main-banner-featured-img">
                    <img src="https://cdn.sanity.io/images/8f2jlyhn/production/dcea467b22c5a12610d95ee1ebdbff22e1608219-800x602.jpg" alt="blog-main-banner-featured-img" />
                </div>
            </div>


            <div className="grid-container contained blog-filter-category">
                <div className="row align--center">
                    <div className="rich-text blog-details mb4 line-break sans--sm">
                        {/* <div className="blog-category-box mb4">
                            <button className="no-hover button--pill--secondary"> Category 01</button>
                            <button className="no-hover button--pill--secondary"> Category 02</button>
                            <button className="no-hover button--pill--secondary"> Category 03</button>
                            <button className="no-hover button--pill--secondary"> Category 04</button>
                        </div> */}
                        <p>Genexa is the first, and only, clean medicine company.  We use the same proven active ingredients you need without the artificial ones you don’t. Genexa's clean medicine focused blog is a space for you to find the answers for questions related to medicine, Genexa's products, and more.</p>
                    </div>
                </div>
                <BlogBox
                    {...pageContext}
                />

            </div>
        </React.Fragment>
    );
};
