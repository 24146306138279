import React, {useState} from 'react';
import _ from 'lodash';
import Link from '../components/Link';
import format from 'date-fns/format';

export default ({
	results = [],
}) => {
	// console.log(`test  ${results}`)
	// const [list, setList] = useState([...results.slice(0, 10)])
	const [visiable, setVisiable] = useState(1);
	const showMoreitems = () => {
		setVisiable((prevValue) => prevValue + 1)
	}

	function getPostData(component){

		let response={ 'title':'','subtitle':'', 'description':'' };
		if(component.length){
			for (let i = 0; i < component.length; i++) {

				if(component[i]._type=='heroBlog'){
					response.title=component[i].title;
					response.subtitle=component[i].subtitle;
				}
				if(component[i]._type=='blogBodyRichText'){
					response.description=component[i].description;
				}
			}
		}
		console.error(response);
		return response;
	}


	return (
		<div>
			{console.log(results)}
			{ results.slice(0, visiable).map(category => (
				<div>
					<div className="row align--center mt4 mb4">
						<div className="col c12 c12--lg">
							<div className="rich-text line-break">
								<h4 className="sans--lg fw--700">{category.title}</h4>
							</div>
						</div>
					</div>


					<div className="container">
						<div className="row pb-5 display-flex">
							{ category.blog_posts && _.compact(category.blog_posts).map(post => (
								<div className="blog-card col c12 c6--md mb8">
									<Link title={getPostData(post.components).title + getPostData(post.components).subtitle } url={`/blog/${post.slug}`}>
										<div className="BlogCard">
											{/* <div className="blog-featured-img">
											<img src="https://cdn.sanity.io/images/bb2040ii/staging/afb9c22f7953bcf756c29bf0de08c9bea4a22aa0-800x602.jpg" alt="" />
										</div> */}
											<h3 className="mt5 mb3 serif--xl serif--xxl--md">{getPostData(post.components).title}</h3>
											{getPostData(post.components).subtitle && <h5>{getPostData(post.components).subtitle}</h5>}
										</div>
									</Link>
									<div className="dib rich-text mb4">
										{post.author?
										<p className="mt0 mb0">
											<strong>Written by </strong>
											<Link title={post.author.name} url={`/blog/author/${post.author.slug}`}>{post.author.name}</Link> on {format(
											new Date(post.postDate),
											'MMMM d, yyyy',
										)}
										</p>:''}
									</div>
								</div>
							))}
						</div>
					</div>


					{/* <div className="container">
						<div className="row pb-5 display-flex">
							{ category.blog_posts && category.blog_posts.slice(0, visiable).map(post => (

								<div className="blog-card col c12 c6--md mb8">
								<Link url={`/blog/${post.slug}`}>
									<div className="BlogCard">
										{/* <div className="blog-featured-img">
											<img src="https://cdn.sanity.io/images/bb2040ii/staging/afb9c22f7953bcf756c29bf0de08c9bea4a22aa0-800x602.jpg" alt="" />
										</div> */}
					{/* <h3 className="mt5 mb3 serif--xl serif--xxl--md">{getPostData(post.components).title}</h3>
										<h5>{getPostData(post.components).subtitle}</h5>
									</div>
								</Link>
									<div className="dib rich-text mb4">
										<p className="mt0 mb0">
											<strong>Written by </strong>
											<Link url={`/blog/author/${post.author.slug}`}>{post.author.name}</Link> on {format(
											new Date(post.postDate),
											'MMMM d, yyyy',
										)}
										</p>
									</div>
								</div>
							))}
						</div>
					</div> */}

				</div>
			))}

			<div className="tc" id="load-more-wrap"><button title="Load more" aria-label="Load More" onClick={showMoreitems} className="button--pill--primary invert">Load More</button></div>
		</div>
	)
};
